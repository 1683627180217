//导入vueRouter
import Vue from "vue";
import VueRouter from "vue-router";
import { routes, staticRouter, whiteList } from '@/utils/menusGlobal';//引入路由数据
import local from "@/utils/local";
import store from "@/store";
//页面组件引入
import Layout from '@/views/admin-layout/Layout.vue'

//重写路由的push，解决跳转当前导航的警告
const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location){
  return originPush.call(this,location).catch(err=>err)
} 

Vue.use(VueRouter);
const router = new VueRouter({
  mode:'hash', //路由模式
  base: process.env.BASE_URL,
  routes: [...routes, ...staticRouter]  //路由
})

router.beforeEach((to, from ,next) => {
  let isLogin = local.get('tk');  //获取token，以此判断是否登录
  /**
    判断顺序：
      是否进入静态页面，是->放行，否->下一步判断
      是否有token，是->验证token是否有效，否->判断进入的是哪个页面
      有token，且验证成功->获取动态路由并放行；验证失败->移除本地token，跳转首页
      没有token，进入后台页面->跳转登录，进入登录页面->放行
  */
 if(whiteList.indexOf(to.path) > 0) {
   next()
  }else {
    if(isLogin) {
      store.dispatch('getRoutes')
      next()
    }else {
      if(to.path == '/login') {
        next()
      } else {
        next({path: '/login'})
      }
    }
  }
})

//导入路由
export default router