<template>
  <div class="promote-register">
    <div class="pro-reg-main">
      <div class="title f24">推广注册</div>
      <div class="reg-form">
        <el-form v-if="toggleStep" class="step-1" :rules="rule1" :model="codeForm" label-width="100px">
          <el-form-item class="img-code" label="图片验证码">
            <el-input v-model="codeForm.code"></el-input>
            <img :src="codeImg" alt="图片验证码" class="img" @click="getImgCode">
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model="codeForm.phone"></el-input>
            <el-button @click="sendSmsCode" type="primary" plain :disabled="minutes>0?true:false"
            >{{minutes <= 0 ? '获取验证码' : minutes}}</el-button>
          </el-form-item>
        </el-form>
        <el-form v-else class="step-2" :model="registeForm" :rules="rules" inline size="small" ref="registeForm" label-width="100px">
          <el-form-item label="短信验证码" prop="SmsCode">
            <el-input v-model="registeForm.SmsCode"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="Name">
            <el-input v-model="registeForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="Account">
            <el-input v-model="registeForm.Account" disabled></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="Password">
            <el-input v-model="registeForm.Password"></el-input>
          </el-form-item>
          <el-form-item label="支付密码" prop="PayPassword">
            <el-input v-model="registeForm.PayPassword"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="Email">
            <el-input v-model="registeForm.Email"></el-input>
          </el-form-item>
          <div style="text-align: center; margin-top: 20px;">
            <el-button size="small" type="primary" @click="submitForm('registeForm')">提 交</el-button>
            <el-button size="small" @click="resetForm('registeForm')">重 置</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getVerifyCode, sendMessageCode, submitRegister } from '@/api/promote/index.js'
import {validatePass, check6Password, checkTel} from '@/utils/validator.js'
  export default {
    data() {
      return {
        codeImg: '',
        codeForm: {
          identifying: '',
          phone: '',
          code: '', //图片验证码
        },
        rule1: {
          phone: [{ required: true, validator: checkTel, trigger: 'blur' }]
        },
        registeForm: {
          SmsCode: '',  //短信验证码
          Name: '',
          Account: '',
          Password: '',
          PayPassword: '',
          InvitationCode: '',  //邀请码
          Email: ''
        },
        toggleStep: true, //切换步骤
        rules: {
          SmsCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
          Name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          Account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
          Password: [{ required: true, validator: validatePass, trigger: 'blur' }],
          PayPassword: [{ required: true, validator: check6Password, trigger: 'blur' }],
          InvitationCode: [{ required: true, message: '请输入邀请码', trigger: 'blur' }],
        },
        minutes: 0,
        timer: null
      }
    },
    created() {
      this.getImgCode()
    },
    methods: {
      getImgCode() {
        getVerifyCode().then(res => {
          if(res.data.code == 0) {
            let {identifying, img} = res.data.data;
            let url = decodeURIComponent(img);
            this.codeImg = url;
            this.codeForm.identifying = identifying
          }
        })
      },
      sendSmsCode() {
        if(!this.codeForm.phone) {
          return this.$message.error('请输入手机号码！')
        }
        this.startCountdown();  //倒计时3分钟
        sendMessageCode(this.codeForm).then(res => {
          let {code, msg } = res.data;
          if(code == 0) {
            this.toggleStep = false;
            this.$message.success(msg);
            this.registeForm.Account = this.codeForm.phone
          } else {
            clearInterval(this.timer)
            this.getImgCode()
            this.$message.error(msg)
          }
        })
      },
      //倒计时
      startCountdown() {
        let seconds = 180; // 设置倒计时的秒数
        this.timer = setInterval(() => {
          if (seconds > 0) {
            --seconds;
            this.minutes = `重发验证码(${seconds}s)`;
          } else {
            clearInterval(this.timer); // 清除定时器
            this.minutes = '重新获取';
          }
        }, 1000);
      },

      submitForm(formName) {
        const code = location.hash.split('=')[1];
        this.registeForm.InvitationCode = code;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            submitRegister(this.registeForm).then(res => {
              if(res.data.code == 0) {
                this.$message.success(res.data.msg);
                this.$router.push({path: '/login'});
              } else {
                this.$message.error(res.data.msg);
                this.toggleStep = true;
              }
            })
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>

<style lang="less" scoped>
.promote-register {
  margin: 0 auto;
  width: 60%;
  height: 100%;
  .pro-reg-main {
    .title {
      margin-top: 20px;
      line-height: 50px;
      font-weight: bold;
    }
    .reg-form {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 400px;
      .step-1 {
        /deep/.el-form-item__content {
          display: flex;
          justify-content: space-around;
          .img {
            height: 40px;
            margin-left: 8px;
          }
          .el-button {
            margin-left: 8px;
          }
        }
      }
      .step-2 {
        width: 600px;
      }
    }
  }
}
</style>