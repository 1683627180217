import { getRouterNav } from "@/api/system/menu";
import Layout from '@/views/admin-layout/Layout.vue'
import router from "../router";
export default {
  state: {
    dynamicRoutes: [],
  },
  mutations: {
    SET_ROUTE(state, data) {
      state.dynamicRoutes = data
    },
  },
  actions: {
    getRoutes({commit}) {
      getRouterNav().then(res => {
        const data = res.data.map(item => {
          item.component = Layout
          if(item['children'] && item['children'].length > 0) {
            item['children'] = item['children'].map((v) => {
              if(v.meta.title == '机票预定') {
                v.meta.keepAlive = true
              }
              let component = () => import('@/views/' + v.component);
              return {
                path: v.path,
                component,
                meta: {
                  keepAlive: false,
                  ...v.meta
                }
              }
            })
          }
          return item
        })
        commit('SET_ROUTE', data)
        data.forEach((item) => {
          router.addRoute(item)
        })
      })
    }
  }
}