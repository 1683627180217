export default {
  state: {
    showDialogPage: false,  //权限管理弹出框
    showDepartDialog: false,  //部门管理
    isEdit: false,
    showClientList: false,  //显示客户名单
  },
  mutations: {
    SET_DIALOG_SHOW(state, value) {
      state.showDialogPage = value
    },
    SET_DEPART_DIALOG(state, value) {
      state.showDepartDialog = value
    },
    SET_IS_EDIT(state, value) {
      state.isEdit = value
    },
    SET_SHOW_CLIENT_LIST(state, value) {
      state.showClientList = value
    }
  }
}